const locales = {
  nl: {
    id: 'nl',
    name: 'Dutch',
    transName: 'Nederlands',
    iso: 'benl',
    path: '/nl',
    flag: '🇳🇱'
  },
  fr: {
    id: 'fr',
    name: 'French',
    transName: 'Français',
    iso: 'befr',
    path: '/fr',
    flag: '🇳🇱'
  },
  de: {
    id: 'de',
    name: 'German',
    transName: 'Deutsch',
    iso: 'de',
    path: '/de',
    flag: '🇩🇪'
  },
  en: {
    id: 'en',
    name: 'English',
    transName: 'English',
    iso: 'en',
    path: '/en',
    flag: '🇬🇧'
  }
}

export default locales
