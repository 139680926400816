/* eslint-disable no-console */
/* eslint-disable import/no-dynamic-require */
import React from 'react'
import { IntlProvider } from 'react-intl'
//
import locales from './locales'

export { default as locales } from './locales'

const defaultLocale = process.env.DEFAULT_LOCALE || locales.nl

export const IntlContext = React.createContext(defaultLocale)
export const IntlContextProvider = IntlContext.Provider
export const IntlContextConsumer = IntlContext.Consumer
export const EnabledLocalesContext = React.createContext({})

const flattenMessages = (nestedMessages, prefix = '') => {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      // eslint-disable-next-line no-param-reassign
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

const getMessages = (key) => {
  try {
    // eslint-disable-next-line global-require
    const messages = require(`./messages/${key}.json`)

    return flattenMessages(messages)
  } catch (error) {
    if (error.code === 'MODULE_NOT_FOUND') {
      // eslint-disable-next-line no-unused-expressions
      process.env.NODE_ENV !== 'test' && console.error(`[gatsby-plugin-intl] couldn't find file "${key}/${key}.json"`)
    }

    throw error
  }
}

export const I18nProvider = ({ currentLocale, locales: providerLocales, children }) => (
  <IntlProvider locale={currentLocale.id} key={currentLocale.id} messages={getMessages(currentLocale.id)}>
    <EnabledLocalesContext.Provider value={providerLocales}>
      <IntlContextProvider value={currentLocale}>{children}</IntlContextProvider>
    </EnabledLocalesContext.Provider>
  </IntlProvider>
)

export default I18nProvider
